import certSQL from "../Assets/certifications/certificado-sql.png";
import certgithub from "../Assets/certifications/certificado-github.png";
import certHenry from "../Assets/certifications/certificado-henry.png";
import certcode from "../Assets/certifications/certificado.jpg";
import certHTML from "../Assets/certifications/HTML_educacionIT.png";
import certfintC from "../Assets/certifications/Certificado-Introducción-a-Csharp.png";
import certintProgramacion from "../Assets/certifications/Certificado-Introducción-a-la-Programación.png";
import certResponsive from "../Assets/certifications/responsive design.png";
import typescriptPlatzi from "../Assets/certifications/typescript-platzi.png";
import certTypescript from "../Assets/certifications/certfificado-typescript_microsoft.png";
import certnative from "../Assets/certifications/react-native.jpg";
import certMongo from "../Assets/certifications/cerificado_mongodb.png";
import fundamentosNode from "../Assets/certifications/certificado-fundamentos_node.png";

const DBCertifications = [
  {
    image: fundamentosNode,
    alt: "FundamentosNode",
  },
  {
    image: certMongo,
    alt: "Certificado MongoDB",
  },
  {
    image: certnative,
    alt: "Certificado_reactNative",
  },
  {
    image: certTypescript,
    alt: "Certificado_typeScript",
  },
  {
    image: typescriptPlatzi,
    alt: "Certificado_typeScript",
  },
  {
    image: certResponsive,
    alt: "Certificado_WebResponsive",
  },
  {
    image: certintProgramacion,
    alt: "Introduccion a programacion",
  },
  {
    image: certfintC,
    alt: "Certificado_C#",
  },
  {
    image: certHTML,
    alt: "Certificado_HTML",
  },
  {
      image: certgithub,
      alt: "Certificado_de_GitHub",
    },
    {
        image: certSQL,
        alt: "Certificado_de_mySQL",
    },
    {
      image: certHenry,
      alt: "Certificado_de_Henry",
    },
  {
    image: certcode,
    alt: "Cert_code",
  },
];

export default DBCertifications;
